.user-layout {
  &.fixed.ant-layout,
  &.fixed .full-layout.ant-layout {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .ant-layout-sider > .ant-layout-sider-children {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

