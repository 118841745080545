.page500 {
  background: #fff;
  .ant-layout-content {
    overflow: hidden !important;
  }
  .error-block {
    margin: 50px
  }
  .center-block {
    margin: 0 100px;
    .error-title {
      font-size: 60px;
      font-weight: 800;
      color: #444;
      margin-bottom: 10px;
    }
    .error-subtitle {
      font-weight: 400;
      font-size: 32px;
      color: #444;
      margin-bottom: 80px;
    }
  }
}
